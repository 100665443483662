<template>
  <div>
    <div>
      <span>
        <a href="/themes?target_id=7">体験プログラムを授業や実習で使ってみよう（教室型、現地型、宿泊型）</a>
      </span>
      <div  class="description">
        プログラム例： 南アルプスの大地の成り立ち、南アルプスの高山植物とシカの影響、親子で南アルプス満喫体験 in 椹島
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tab-content-3"
}
</script>
