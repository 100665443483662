import Vue from 'vue'
import tab from "../../../javascript/components/public/common/tab.vue"
document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector("#tab-content")
    const instagramView = el.dataset.instagramView == 'true'

    if (!el) return
    const app = new Vue({
        render: h => h(tab, {
            props: {
                instagramView: instagramView
            }})
    }).$mount();
    el.appendChild(app.$el);
});


