<template>
  <div class="content-tab" v-if="!isMobile">
    <div class="tab-container" >
      <ul class="tabs">
        <li :class="tabLinkClass(1)" data-tab="tab-1" v-on:click="setCurrent(1)"><a href="#t-1">見る</a></li>
        <li :class="tabLinkClass(2)" data-tab="tab-2" v-on:click="setCurrent(2)"><a href="#t-2">学ぶ</a></li>
        <li :class="tabLinkClass(3)" data-tab="tab-3" v-on:click="setCurrent(3)"><a href="#t-3">ふれる</a></li>
      </ul>
    </div>
    <div :class="tabContentClass(1)" id="tab-1" v-show="current == 1"><tab-content1 :instagram-view="instagramView"></tab-content1></div>
    <div :class="tabContentClass(2)" id="tab-2"  v-show="current == 2"><tab-content2></tab-content2></div>
    <div :class="tabContentClass(3)" id="tab-3" v-show="current == 3"><tab-content3></tab-content3></div>
  </div>
  <div class="tab-container mobile"  v-else>
    <div :class="tabLinkClass(1)" data-tab="tab-1" v-on:click="toggleContent(1)">
      <span>
        <a href="#t-1">
          見る
        </a>
      </span>
      <span class="icon" v-show="current == 1"><i class="fas fa-minus"></i> </span>
      <span class="icon" v-show="current != 1"><i class="fas fa-plus"></i> </span>
    </div>
    <div :class="tabContentClass(1)" id="tab-1" v-show="current == 1">
      <tab-content1 :instagram-view="instagramView"></tab-content1>
    </div>

    <div :class="tabLinkClass(2)" data-tab="tab-2" v-on:click="toggleContent(2)">
      <span>
        <a href="#t-2">
          学ぶ
        </a>
      </span>
      <span class="icon" v-show="current == 2"><i class="fas fa-minus"></i> </span>
      <span class="icon" v-show="current != 2"><i class="fas fa-plus"></i> </span>
    </div>
    <div :class="tabContentClass(2)" id="tab-2"  v-show="current == 2"><tab-content2></tab-content2></div>
    <div :class="tabLinkClass(3)" data-tab="tab-3" v-on:click="toggleContent(3)">
      <span>
        <a href="#t-3">
          ふれる
        </a>
      </span>
      <span class="icon" v-show="current == 3"><i class="fas fa-minus"></i> </span>
      <span class="icon" v-show="current != 3"><i class="fas fa-plus"></i> </span>
    </div>

    <div :class="tabContentClass(3)" id="tab-3" v-show="current == 3"><tab-content3></tab-content3></div>

    <div class="about">
      <div><a href="/about">本サイトについて</a></div>
      <div><a href="/for_teachers">教育での活用について</a></div>
      <div><a href="/terms">ご利用規約</a></div>
      <div><a href="/about_photo">写真投稿方法について</a></div>
    </div>
  </div>
</template>

<script>
import tabEye from '../../../../assets/images/public/tab-eye.png'
import tabQuestion from '../../../../assets/images/public/tab-question.png'
import tabFinger from '../../../../assets/images/public/tab-finger.png'
import tabContent1 from './tab/tab-content-1.vue'
import tabContent2 from './tab/tab-content-2.vue'
import tabContent3 from './tab/tab-content-3.vue'
export default {
  name: "tab",
  props: [ 'instagramView' ],
  components: {
    tabContent1, tabContent2, tabContent3
  },
  data() {
    return {
      current: 1,
      isMobile: false
    }
  },
  mounted() {
    if(location.hash && location.hash.startsWith('#t-')) {
      const id =location.hash.replace('#t-','')
      this.current = parseInt(id)
    }
    window.addEventListener('resize', this.calculateWindowWidth)
    this.calculateWindowWidth()
  },
  computed: {
    tabEye() {
      return tabEye
    },
    tabFinger() {
      return tabFinger
    },
    tabQuestion() {
      return tabQuestion
    }
  },
  methods: {
    calculateWindowWidth() {
     this.isMobile = window.innerWidth < 768
    },
    tabLinkClass(id) {
      if (id == this.current) {
        return 'tab-link current'
      }
      else {
        return 'tab-link'
      }
    },
    tabContentClass(id) {
      if (id == this.current) {
        return 'tab-content current'
      }
      else {
        return 'tab-content'
      }
    },
    setCurrent(id) {
      this.current = id
      location.hash = `#t-${id}`
    },
    toggleContent(id) {
      if(id == this.current) {
        this.current = 0
      }
      else {
        this.current = id
      }
    }
  }
}
</script>

<style scoped>

</style>