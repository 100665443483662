<template>
  <div>
    <div><span><a href="/themes?target_id=3">南アルプスの学習問題「南アルプスを学ぼう」にチャレンジ（小学生向け）</a></span></div>
    <div><span><a href="/themes?target_id=4">南アルプスの学習問題「南アルプスを学ぼう」にチャレンジ（中学生以上向け）</a></span>
      <div class="description">
        学習問題テーマは、「南アルプスとは？」、「高山植物にせまる危機」、「ライチョウを知ろう」、「高山で暮らす生きものたち」、「せりあがるけわしい山々」など
      </div>
    </div>
    <div><span><a href="/contents">南アルプスのことを調べる（貴重な動植物の解説）</a></span></div>
  </div>
</template>

<script>
export default {
  name: "tab-content-2"
}
</script>
